import _crypto from "crypto";
import _fastSafeStringify from "fast-safe-stringify";
import _buffer from "buffer";
var exports = {};
var Buffer = _buffer.Buffer;
var createHash = _crypto.createHash;
var stringify = _fastSafeStringify;

exports = function shasum(input, hash, digest) {
  if (!hash) hash = "sha1";
  if (!digest) digest = "hex";
  if (typeof input !== "string" && !Buffer.isBuffer(input)) input = stringify.stable(input);
  return createHash(hash).update(input, typeof input === "string" ? "utf8" : undefined).digest(digest);
};

export default exports;